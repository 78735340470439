import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "2024 Group.jpg" }) {
          name
          publicURL
        }
      }
    `}
    render={data => 
      <img 
        src={data.placeholderImage.publicURL} 
        alt={data.placeholderImage.name}
      />
    }
  />
)
export default Image
