import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Wrapper from '../components/Wrapper'
import Photo from './image'
import Photo2 from './image2'

export default () => (
  <Wrapper>
    <H2>2024 Conference - March 13-15, 2024</H2>
    <IMGdiv>
      <Photo />
      <ImgText>
        <p>
        We hope you all will consider attending the ALAIR 2024 conference in Birmingham! More information will be coming soon! 
        </p>
        <p>
              Save The Date!
              <br />ALAIR 2024 Conference
              <br />March 13-15
              <br />UAB Campus in Birmingham, AL
        {/*
        In total, there were 58 attendees at the conference, representing both two-year and four-year institutions across the state of Alabama. We would like to extend a special thank you to our platinum sponsors, SPOL and Watermark, as well as the conference presenters and those who were able to attend. We are grateful for your continuing service towards the organization and the conference would not have been possible without your generous contributions. This year, we were fortunate to have presentations on a variety of topics including data visualization, employee workstyles, SACSCOC, and other areas related to data-informed decision making. Additionally, we held our first panel discussion where attendees had the opportunity to collaborate with faculty and enrollment management staff to aid in greater productivity, efficiency, and effectiveness of the IR/IE office. After a fun evening at the Wharf, the conference concluded with a wonderful message from keynote speaker, John S. Riley. The full conference program and slides from some presentations can be found under the “ALAIR Conference 2022” section under the <a href="/resources">Resources</a> tab.
        */}
        </p>
        <Photo2 />
      </ImgText>
    </IMGdiv>
    <Paper>
      <LitHead>Conference Resources</LitHead>
      <div style={{ paddingTop: '1rem' }}>
        <ul>
          {/* <li>
            <a href="/forms/ALAIR Vendor Sponsorship Form 2022-Fillable.pdf" target="_blank">
              2022 Vendor Registration Form
            </a>
          </li> */}
          <li>
            <a href="/forms/ALAIR Presentation Proposal Form 2024-Fillable.pdf" target="_blank">
              2024 Conference Presentation Proposal Form
            </a>
          </li>
          <li>
            <a href="/forms/ALAIR Conference Registration Form 2024-Fillable.pdf" target="_blank">
              2024 ALAIR Conference Registration Form
            </a>
            </li>
            <li>
              <a
                href="/forms/ALAIR Vendor Sponsorship Form 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Vendor Sponsorship Form 2024-Fillable
              </a>
            </li>
            {/* 
          <li>
            <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1642599226162&key=GRP&app=resvlink" target="_blank">
              Springhill Suites at the Wharf 
            </a>
          </li> */}
          {/*
          <li>
            <Link to="/conference/2020-alair-conf-images/">
              Conference 2020 Photos
            </Link>
          </li>
          <li>
            <a href="/minutes/2020Mar13-ALAIR_AnnualBusMtg-DRAFT.pdf"
            target="_blank"
            >
              2020 Conference Minutes – Draft for approval in 2021
            </a>
          </li>
          <li>
            <a href="/treasurersreports/TreasurerRpt2020.pdf">
              Treasurer's Report 2020
            </a>
          </li>
          */}
        </ul>

        {/* <h4 id="upcomingevents">Upcoming Events</h4>
        <p>
        The AIR Forum 2022 will be conducted in person June 6 - 9, 2022 in Phoenix, AZ. Save the date and register in February 2022 at <a href="https://www.airweb.org/forum/2022/home" target="_blank">https://www.airweb.org/forum/2022/home</a>.
        </p>
        <p>
        Save the Date for SAIR 49: October 1 - 4, 2022 at the Hotel Monteleone in New Orleans, LA.
        </p> */}

        <h4 id="stayconnected">Stay Connected</h4>
        <ul>
          <li>
            <a href="https://www.facebook.com/AlabamaAIR/" target="_blank">
              ALAIR on Facebook
            </a>
          </li>
          <li>
            <a href="https://alair.org/listserv/" target="_blank">
              Join the ALAIR Listserv
            </a>
          </li>
        </ul>

        {/*
        <h4 id="conference2020presentations">Conference 2020 Presentations</h4>
        <ul>
          <li>
            <a href="/forms/KEYNOTE-StudentAchievement-Nuria Cuervas.pdf" download>
              KEYNOTE - Student Achievement - Nuria Cuervas
            </a>
          </li>
          <li>
            <a
              href="/forms/Getting Things Done-Andrew Davis.pdf"
              download
            >
              Getting Things Done - Andrew Davis
            </a>
          </li>
          <li>
            <a
              href="/forms/Increasing Retention of Remedial Students-Tamara Jones and Jonathan Broyles.pdf"
              download
            >
              Increasing Retention of Remedial Students - Tamara Jones and Jonathan Broyles
            </a>
          </li>
          <li>
            <a
              href="/forms/PowerBI-Michelle Tin.pdf"
              download
            >
              PowerBI - Michelle Tin
            </a>
          </li>
          <li>
            <a
              href="/forms/SQL Joins-Anran Xing and Michelle Tin.pdf"
              download
            >
              SQL Joins - Anran Xing and Michelle Tin
            </a>
          </li>
          <li>
            <a
              href="/forms/The First Year-Lisa Kimble.pdf"
              download
            >
              The First Year - Lisa Kimble
            </a>
          </li>
        </ul>
        */}
      </div>
    </Paper>
  </Wrapper>
)

const H2 = styled.h2`
  font-size: 2.5em;
  margin-top: 0.5em;
`

const IMGdiv = styled.div`
  padding: 0em 0em 0em 0em;
`

const ImgText = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
  width: 100%;
`
const LitHead = styled.h3`
  display: inline;
  text-decoration: none;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(transparent, transparent),
    linear-gradient(-75deg, #07588a, #39cccc);
  /* linear-gradient(-75deg,#07588a,#39cccc) */
  background-repeat: no-repeat;
  background-position: 120%, 122%, 0 130%;
  background-size: 100% 10px;
`
const Paper = styled.div`
  padding: 2em 1em 1em 1em;
  background: rgba(57, 204, 204, 0.2);
  box-shadow: 0 20px 68px rgba(0, 0, 0, 0.05);
`
